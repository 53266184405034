
.LoginForm {
    padding-left: 20px;
    margin-left: 20px;
  }
  .RModal {
    color:#343339;
    margin-right:15%;
    margin-left:15%;
    margin-top:10%;
  }
  