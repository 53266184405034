:root{
    --selected-condition-color:var(--ECOM-color);
    --ECOM-color:lightblue;
    --REFURB-color:#ffe70d;
    --SLVG-PLT-color:rgb(219, 26, 26);
    --TGT-AP-color:rgb(106, 212, 0);
    --TGT-SH-color:rgb(106, 212, 0);
    --TGT-UG-color:rgb(106, 212, 0);
    --TGT-HC-color:#ff890d;
    --TGT-HC-color:#ff890d;
    --TGT-SW-color: rgb(226, 181, 181);
    --TGT-X-color: rgb(226, 181, 181);
    }
.associate_main{
    width:auto;
    height:auto;
    background-color: lightblue;
    }
.associate_menu_flex{
    width:100%;
    height:100%;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    background-color: lightblue;
    }
.associate_menu_button{
    margin-left: 3%;
    background-color: lightcoral;
    width:94%;
    height:10%;
    margin-top:30px;
    border: black;
    border-style: solid;
    border-radius: 10px;
    position:relative;
    display: inline-block;
    background-color:#0c6a9c;
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    color: ghostwhite;
    font-size: 50px;
    font-weight: 600 ;
    }
.main{
    height:100%;
    background: #eee;
    justify-content: center;
    align-items: center;
    width:100%;
    position:absolute;
    }
.BottomStyled{
    width: 98%;
    height: auto;
    min-height: 87%;
    left: 0px;
    padding: 0;
    padding-left:1%;
    padding-right:1%;
    background-color:#fefdfd;
    }
.bold{
    font-weight: bold;
    }
.biggerbold{
    font-weight:bold;
    font-size:20px;
    color: #060130;
    }
.biggerboldYellow{
    animation: blinker 1s linear infinite;
    font-weight:bold;
    font-size:20px;
    color: #060130;
    }
    @keyframes blinker {
        50% {
          opacity: 0;
            }
        }
.results{
    height:18px;
    overflow: hidden;
}
.DropdownMainButton{
    margin-top:-1px;
    width: 70px; 
    border-radius: 3px;
    border: none;
    position:relative;
    display: inline-block;
    background-color:#0c6a9c;
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    color: ghostwhite;
    font-size: 20px;
    }
.DropDownBlock{
    position:absolute;
    width:8%;
    list-style-type: none;
    border-radius:4px;
    left:86%;
    }
.ecom_search_input{
    height:100%;
    width:35%;
    color:#45240A;
    font-family: Arial,Times,Helvetica,sans-serif;
    font-size:16px;
    }
.ecom_search_button{
    height:100%;
    width:35%;
    color:#45240A;
    font-family: Arial,Times,Helvetica,sans-serif;
    font-size:16px;   
    }
@media screen and (min-width: 320px) {
    .ecom_flex {
        display: flex;
        flex-direction: column;
        width:100%;
        background-color: lightblue;
        }
    .exom_flex_yellow{
        background-color:rgb(226, 217, 168);
        margin-top:20px;
        display: flex;
        flex-direction: column;
        width:100%;
        background-color: white;
        }
    .ecom_description{
        margin-left:1%;
        width:96%;
        height:50px;
        margin-top:20px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        border-width: 3px;
        position:relative;
        display: inline-block;
        background-color:#0c6a9c;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: ghostwhite;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        }
    .ecom_description_yellow{
        margin-left:1%;
        width:96%;
        height:50px;
        margin-top:20px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        border-width: 3px;
        position:relative;
        display: inline-block;
        background-color:rgb(255, 255, 51);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color:black;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        }
    .ecom_search_bar{
        display: flex;
        flex-direction: column;
        }
    .ecom_search_title{
        margin-left: 1%;
        width:96%;
        height:50px;
        margin-top:15px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        background-color:#0c6a9c;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: ghostwhite;
        text-align: center;
        font-size: 40px;
        font-weight: 600 ;
        }
    .ecom_search_title_yellow{
        margin-left: 1%;
        width:96%;
        height:50px;
        margin-top:15px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        background-color:rgb(255, 255, 51);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color:black;
        text-align: center;
        font-size: 40px;
        font-weight: 600 ;
        }
    .ecom_search_input{
        margin-left: 1%;
        width:96%;
        height:50px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        text-align: center;
        font-size: 35px;
        font-weight: 600 ;
        background-color: whitesmoke;
        }
    .ecom_search_button_clear{  
        margin-left:1%;    
        width:96%;
        height:50px;
        margin-top:5px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        background-color:rgb(212, 9, 9);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: ghostwhite;
        line-height:50px;
        text-align: center;
        font-size: 40px;
        font-weight: 600 ;
        }
    .ecom_search_button_confirm{      
        margin-left:1%;
        width:96%;
        height:50px;
        margin-top:5px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        background-color:rgb(93, 207, 83);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:50px;
        text-align: center;
        font-size: 40px;
        font-weight: 600 ; 
        }
    .ecom_search_empty_space{
        width:100%;
        height:1px;
        }
    .ecom_search_button_back{
        margin-left:1%;
        width:96%;
        height:50px;
        margin-top:5px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        background-color:whitesmoke;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:50px;
        text-align: center;
        font-size: 40px;
        font-weight: 600 ; 
        margin-bottom:0px;
        }
    .ecom_FormikErrors{
        margin-top:5px;
        height:20px;
        line-height:20px;
        width:96%;
        text-align: center;
        color:red;
        font-size:15px;
        }
    .sort_results_none{
        width:96%;
        height:50px;
        margin-top:20px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        background-color:lightgray;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height: 50px;
        text-align: center;
        font-size: 30px;
        font-weight:600 ; 
        }
    .sort_results_ecom{
        width:96%;
        height:50px;
        margin-top:20px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        background-color:greenyellow;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 600 ; 
        }
    .sort_results_ml{
        width:96%;
        height:50px;
        margin-top:20px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        background-color: rgb(212, 9, 9);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height: 50px;
        text-align: center;
        font-size: 25px;
        font-weight: 600 ; 
        }
    .sort_search_button_back{
        width:96%;
        height:50px;
        margin-top:20px;
        border: black;
        border-style: solid;
        border-radius: 10px;
        position:relative;
        display: inline-block;
        background-color:whitesmoke;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:50px;
        text-align: center;
        font-size: 40px;
        font-weight: 600 ; 
        margin-bottom:165px;
        }
    }
@media screen and (min-width: 730px) {
    .ecom_flex {
        margin-top:20px;
        display: flex;
        flex-direction: column;
        width:100%;
        background-color: white;
        /* Apply some styles */
        }
    .exom_flex_yellow{
        margin-top:20px;
        display: flex;
        flex-direction: column;
        width:100%;
        background-color: white;
        }
    .ecom_description{
        height:30px;
        width:100px;
        color:#45240A;
        font-family: Arial,Times,Helvetica,sans-serif;
        font-size:16px;
        align-self: baseline;  
        margin-left: 0%;
        margin-top:0px;
        border: black;
        border-style: none;
        border-radius: 0px;
        position:relative;
        background-color:white;
        font-weight: 600 ;
        }
    .ecom_description_yellow{
        height:30px;
        width:100px;
        color:#45240A;
        color:black;
        font-family: Arial,Times,Helvetica,sans-serif;
        font-size:16px;
        align-self: baseline;  
        margin-left: 0%;
        margin-top:0px;
        border: black;
        border-style: none;
        border-radius: 0px;
        position:relative;
        background-color:white;
        font-weight: 600 ;
        }
    .ecom_search_bar{
        display: flex;
        flex-direction: row;
        }
    .ecom_search_title{
        height:30px;
        width:100px;
        color:#45240A;
        font-family: Arial,Times,Helvetica,sans-serif;
        font-size:16px;
        align-self: baseline;  
        margin-left: 0%;
        margin-top:0px;
        border: black;
        border-style: none;
        border-radius: 0px;
        position:relative;
        background-color:white;
        font-weight: 600 ;
        }
    .ecom_search_title_yellow{
        height:30px;
        width:100px;
        color:#45240A;
        font-family: Arial,Times,Helvetica,sans-serif;
        font-size:16px;
        align-self: baseline;  
        margin-left: 0%;
        margin-top:0px;
        border: black;
        border-style: none;
        border-radius: 0px;
        position:relative;
        background-color:white;
        font-weight: 600 ;
        }
    .ecom_search_input{
        height:16px;
        width:150px;
        color:#45240A;
        font-family: Arial,Times,Helvetica,sans-serif;
        font-size:16px;
        align-self: left;        
        margin-left: 0%;
        margin-top:0px;
        border: black;
        border-style: solid;
        border-width:1px;
        border-radius:5px;
        position:relative;
        background-color:white;
        font-weight: 600 ;
        }
    .ecom_search_button_clear{      
        width:100px;
        height:25px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        display: inline-block;
        background-color:rgb(212, 9, 9);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: ghostwhite;
        line-height:25px;
        text-align: left;
        padding-bottom: 2px;
        font-size: 17px;
        font-weight: 500 ;
        }
    .ecom_search_button_confirm{      
        width:100px;
        height:25px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        display: inline-block;
        background-color:rgb(93, 207, 83);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:25px;
        text-align: left;
        padding-bottom: 2px;
        font-size: 17px;
        font-weight: 500 ; 
        }
    .ecom_search_button_back{
        width:100px;
        height:25px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        display: inline-block;
        background-color:whitesmoke;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:25px;
        text-align: center;
        text-align: left;
        padding-bottom: 2px;
        font-size: 17px;
        font-weight: 500 ;
        }
    .ecom_search_empty_space{
        width:100%;
        height:1px;
        }
    .ecom_FormikErrors{
        margin-top:5px;
        height:17px;
        line-height:20px;
        width:150px;
        display:inline;
        text-align: center;
        color:red;
        font-size:17px;
        }
    .sort_results_none{
        width:250px;
        height:40px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        background-color:lightgray;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:40px;
        text-align: center;
        padding-bottom: 2px;
        padding-left:5px;
        font-size: 17px;
        font-weight: 500 ;
        }
    .sort_results_ecom{
        width:250px;
        height:40px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        background-color:greenyellow;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:40px;
        text-align: center;
        padding-bottom: 2px;
        padding-left:5px;
        font-size: 17px;
        font-weight: 500 ;
        }
    .sort_results_ml{
        width:250px;
        height:40px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        background-color:rgb(212, 9, 9);
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:40px;
        text-align: center;
        padding-bottom: 2px;
        padding-left:5px;
        font-size: 17px;
        font-weight: 500 ;
        }
    .sort_search_button_back{
        width:100px;
        height:25px;
        margin-top:10px;
        border: black;
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        position:relative;
        display: inline-block;
        background-color:whitesmoke;
        font-family: Segoe UI, Times, Helvetica, sans-serif;
        color: rgb(37, 36, 36);
        line-height:25px;
        text-align: center;
        text-align: left;
        padding-bottom: 2px;
        font-size: 17px;
        font-weight: 500 ;
        }
    }

.FormikErrors{
    display:inline;
    color:red;
    font-size:13px;
}
.SubItem{
    position:relative;
    background-color:#0c4e72;
    color:white;
    width:140px; 
    height:30px;
    border: 2px;
    list-style-type: none;
    font-size: 14px;
    }
    .SubItem:hover{
        color: #ff890d;
        }
.fileInput{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
    }
.fileInputLabel{
    position:relative;
    justify-self: center;
    align-self: center;
    width:100px;
    height:20px;
    border:solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #0c4e72;
    padding:5px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:2px;
    background-color:#0c6a9c;
    color:rgb(236, 236, 236);
    margin-top: 20px;
}
.scanner_modal_menu{
    border-style: solid;
    border-color: black;
    border-width: 1px;
    margin-top: 10%;
    display:flex;
    flex-wrap: nowrap;
    justify-content:space-evenly;
    width:400px;
    padding:5%;
    height:auto;
    }
.scanner_modal_menu_button_oveerride{
    height:60px;
    width: 90px;        
    font-size: 15px;
    background-color: rgb(219, 26, 26);
    font-weight: 600;
    }
.scanner_modal_menu_button_category{
    height:60px;        
    font-size: 15px;
    font-weight: 600;
    background-color:var(--selected-condition-color)
    }
.scanner_modal_menu_button_condition{
    height:60px;
    width:90px;        
    font-size: 15px;
    font-weight: 600;
    background-color: #ffe70d;
    /*background-color:var(--selected-condition-color)*/
    }
.scanner_modal_menu_button_confirm{
    height:60px;
    width:90px;        
    font-size: 15px;
    font-weight: 600;
    background-color: rgb(106, 212, 0);
    }
.modal_condition{
    position:absolute;
    top:25%;
    left:26%;
    display:flex;
    align-items: center;
    justify-content: center;
    justify-self:center;
    height: 100px;
    padding-bottom: 15%;
    width:48%;
    background: whitesmoke;
    border: 3px solid black;
    flex-direction: column; 
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    }
.modal_condition_options_box{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    }
.modal_condition_options_button{
    height:60px;
    width:170px;        
    font-size: 17px;
    font-weight: 600;
    margin: 12px;
    }
.modal_condition_title{
    margin-top: 22%;
    margin-bottom: 3%;
    font-size: 26px;
    font-weight: 700;
    }
.modal_override{
    position:absolute;
    top:18%;
    left:16%;
    display:flex;
    align-items: center;
    justify-content: center;
    justify-self:center;
    height: 400px;
    padding-bottom: 1%;
    width:66%;
    background: whitesmoke;
    border: 3px solid black;
    flex-direction: column; 
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    }
.modal_override_v_5_2{
    position:absolute;
    top:18%;
    left:25%;
    display:flex;
    align-items: center;
    justify-content: center;
    justify-self:center;
    height: 300px;
    padding-bottom: 1%;
    width:50%;
    background: whitesmoke;
    border: 3px solid black;
    flex-direction: column; 
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    }
.modal_override_title{
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 26px;
    font-weight: 700;
    }
.modal_override_options_box{
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    height:280px;
    width:800px;        
    font-size: 17px;
    font-weight: 600;
    margin: 12px;
    overflow-y: auto;
    }
.modal_override_options_box_v_5_2{
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    height:150px;
    width:500px;        
    font-size: 17px;
    font-weight: 600;
    margin: 12px;
    overflow-y: auto;
    }   
.modal_override_options_button{
    height:50px;
    width:130px;        
    font-size: 16px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
    }
.modal_override_options_big_button{
    height:70px;
    width:200px;        
    font-size: 24px;
    font-weight: 700;
    margin: 10px;
    text-align: center;
    }
.whls_add_options_box{
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    height:auto;
    width:500px;        
    font-size: 17px;
    font-weight: 600;
    margin: 12px;
    margin-left: 68px;
    }  
.whls_add_main_container{
    position:absolute;
    top:18%;
    left:25%;
    display:flex;
    align-items: center;
    justify-content: center;
    justify-self:center;
    height: auto;
    padding-bottom: 1%;
    width:50%;
    background: whitesmoke;
    border: 3px solid black;
    flex-direction: column; 
    font-family: Segoe UI, Times, Helvetica, sans-serif;
    } 
.whls_add_print_button{
    height:80px;
    width:200px;        
    font-size: 32px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
    }
.modal_whls_add_reprint_button{
    height:50px;
    width:63%;
    font-size:25px;
    margin-bottom: 10px;
    text-align:center;
    background-color: yellow;
    }
@media all {
    .page-break {
        display: none;
    }
    }
    
    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }
    
    @media print {
    .page-break {
        margin-top: 0.5rem;
        display: block;
        page-break-before: auto;
    }
    }
    
    @page {
    margin-left: 10mm;
    size: landscape;
    }
.modalbuttonquerylist{
    font-size:17px;
    max-height: 350px;
    width:100%;
    top:10%;
    }
.formBodyEx{
    display:flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    }
.formBodyScroll{
    margin: 10px;
    justify-content: space-between;
    overflow-y: auto;
    min-width:200px;
    }
.options_list_fontsEx{
    color:black;
    font-size:15px;
    width:100%;
    }